<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Subcategory title"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="categoryHandle"
                  :items="allCategory.results.list"
                  label="Category"
                  item-text="title"
                  item-value="categoryHandle"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="sort"
                  outlined
                  label="Sort"
                  class="mr-btm-minus"
                  :rules="[sortRules.required, sortRules.nan]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="statuses"
                  label="Status"
                  outlined
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <quill-editor
                  ref="myQuillEditor"
                  v-model="description"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @ready="onEditorReady($event)"
                  :rules="descriptionRules"
                />
              </v-col>
              <v-col cols="12">
                <image-uploader
                  :preview="true"
                  :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                  capture="environment"
                  :debug="1"
                  doNotResize="gif"
                  :autoRotate="true"
                  outputFormat="file"
                  @input="setImage"
                  class="imagebox"
                >
                  <label for="fileInput" slot="upload-label">
                    <figure>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                      >
                        <path
                          class="path1"
                          d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                        ></path>
                      </svg>
                    </figure>
                    <span class="upload-caption">{{
                      hasImage ? "Replace" : "Click to upload"
                    }}</span>
                  </label>
                </image-uploader>
              </v-col>
              <v-col>
                <div class="rmimagebox">
                  <img
                    :src="'http://localhost:3002' + imageUrl"
                    alt=""
                    class="uploadedImage"
                  />
                </div>
              </v-col>
              <v-col cols="12" class="submitbtn">
                <v-btn
                  :disabled="!valid"
                  class="submitbutton btn-dark"
                  @click="validate"
                  v-if="!allLoadingCurd"
                >
                  Add {{ headercontent.title }}
                </v-btn>
                <v-progress-circular
                  v-if="allLoadingCurd"
                  indeterminate
                ></v-progress-circular>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import ImageUploader from "vue-image-upload-resize";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddSubcategory",
  components: { PageHeader, ImageUploader },
  data: () => ({
    valid: true,
    items: ["Foo", "Bar", "Fizz", "Buzz"],
    msg: "Vue Image Upload and Resize Demo",
    hasImage: false,
    image: null,
    title: "",
    description: "",
    categoryHandle: "",
    sort: 0,
    headercontent: {
      title: "Add Subcategory",
      addlink: "/dashboard/subcategory/add",
      downloadlink: true,
    },
    content: "<h2>I am Example</h2>",
    editorOption: {
      // Some Quill options...
    },
    titleRules: [(v) => !!v || "Category Title is required"],
    statusRules: [(v) => !!v || "Status is required"],
    sortRules: {
      required: (value) => !!value || "Sort is required.",
      nan: (v) => !isNaN(v) || "Sort should be number",
    },
    descriptionRules: [(v) => !!v || "Description is required"],
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    status: true,
    imageUrl: null,
  }),
  methods: {
    removeImage() {
      this.imageUrl = "";
    },
    validate() {
      if (this.$refs.form.validate()) {
        let fd = new FormData();
        fd.append("title", this.title);
        fd.append("description", this.description);
        fd.append("sort", this.sort);
        fd.append("status", this.status);
        fd.append("categoryHandle", this.categoryHandle);

        if (this.$route.params.slug == undefined) {
          if (this.image != null) {
            fd.append("image", this.image);
            fd.append("fileType", "images");
            fd.append("imageFile", "categories");
            this.addSubcategory(fd);
          } else {
            this.addSubcategory(fd);
          }
        } else {
          if (this.image != null) {
            fd.append("id", this.id);
            fd.append("image", this.image);
            fd.append("fileType", "images");
            fd.append("imageFile", "categories");
            this.updateSubcategory(fd);
          } else {
            if (this.imageUrl == null) {
              fd.append("id", this.id);
              this.updateSubcategory(fd);
            } else {
              console.log(this.imageUrl);
              fd.append("id", this.id);
              fd.append("imageUrl", this.imageUrl);
              this.updateSubcategory(fd);
            }
          }
        }
      }
    },
    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log("Info", output.info);
      console.log("Exif", output.exif);
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    ...mapActions([
      "fetchCategory",
      "addSubcategory",
      "fetchSubCategoryBySlug",
      "updateSubcategory",
    ]),
  },
  computed: {
    ...mapGetters(["allLoadingCurd", "allSubcategory", "allCategory"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  mounted() {},

  async created() {
    this.fetchCategory({
      categoryType: "job",
    });
    await this.fetchSubCategoryBySlug(this.$route.params.slug);
    if (this.$route.params.slug != undefined) {
      this.title = this.allSubcategory.title;
      this.categoryHandle = this.allSubcategory.categoryHandle;
      this.description = this.allSubcategory.description;
      this.sort = this.allSubcategory.sort;
      this.status = this.allSubcategory.status;
      this.imageUrl = this.allSubcategory.imageUrl;
      this.id = this.allSubcategory._id;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 300px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.rmimagebox {
  position: relative;
  width: 200px;
  img {
    width: 100%;
  }
  .v-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
